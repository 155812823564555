var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('b-card', [_c('form-wizard', {
    ref: "refFormWizard",
    staticClass: "checkout-form-wizard steps-transparent",
    attrs: {
      "title": null,
      "subtitle": null,
      "hide-buttons": ""
    }
  }, [_c('tab-content', {
    attrs: {
      "title": _vm.$t('account'),
      "icon": "feather icon-user"
    }
  }, [_c('agencies-add-tab-account', {
    attrs: {
      "agency-data": _vm.agencyData
    },
    on: {
      "update:agencyData": function updateAgencyData($event) {
        _vm.agencyData = $event;
      },
      "update:agency-data": function updateAgencyData($event) {
        _vm.agencyData = $event;
      },
      "next-step": _vm.formWizardNextStep
    }
  })], 1), _c('tab-content', {
    attrs: {
      "title": _vm.$t('confirmation'),
      "icon": "feather icon-check"
    }
  }, [_c('agencies-add-tab-confirm', {
    attrs: {
      "agency-data": _vm.agencyData
    },
    on: {
      "update:agencyData": function updateAgencyData($event) {
        _vm.agencyData = $event;
      },
      "update:agency-data": function updateAgencyData($event) {
        _vm.agencyData = $event;
      },
      "prev-step": _vm.formWizardPrevStep,
      "submit": _vm.submitHandle
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }