<template>
  <section>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="agencyData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('agency.errFetchAgencyData') }}
      </h4>
      <!-- <div class="alert-body">
        {{ $t('agency.goToAgency_1') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-agencies-list'}"
        >
          {{ $t('agency.goToAgency_2') }}
        </b-link>
      </div> -->
    </b-alert>

    <!-- Content -->
    <b-card
      v-if="agencyData"
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-1"
      class="border"
    >
      <template #header>
        <h6 class="m-0">
          {{ $t('previewMail') }}
        </h6>
      </template>
      <b-card-body>
        <h3> {{ $t('createdMsg_1') }}</h3>

        <b-card-text class="py-2">
          {{ $t('sendTo') }} <span class="font-weight-bold ">{{ agencyData.emailAddress }}</span>
        </b-card-text>

        <b-card-text class="font-weight-bold">
          {{ $t('createdMsg_2') }}
        </b-card-text>

        <b-list-group class="mb-2">
          <b-list-group-item>
            <b-row>
              <b-col
                cols="3"
              >
                {{ $t('email') }}
              </b-col>
              <b-col>
                {{ agencyData.emailAddress }}
              </b-col>
            </b-row>
          </b-list-group-item>

          <b-list-group-item>
            <b-row>
              <b-col
                cols="3"
              >
                {{ $t('password') }}
              </b-col>
              <b-col>
                <span class="text-muted">{{ $t('passwordMuted') }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>

        </b-list-group>

        <b-card-text class="font-weight-bold mt-3">
          {{ $t('createdMsg_3') }}
        </b-card-text>
      </b-card-body>

      <div class="d-flex justify-content-center">
        <!-- Back button -->
        <b-button
          variant="secondary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="$emit('prev-step')"
        >
          {{ $t('back') }}
        </b-button>

        <!-- Next button -->
        <b-button
          variant="info"
          class="mb-1 mb-sm-0 ml-0 ml-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="$emit('submit')"
        >
          {{ $t('send') }}
        </b-button>
      </div>
    </b-card>
  </section>
</template>

<script>

import {
  BButton, BRow, BCol,
  BCard,
  BCardText,
  BCardBody,
  BListGroup,
  BListGroupItem, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BListGroup,
    BListGroupItem,
    BAlert,
  },
  props: {
    agencyData: {
      type: Object,
      required: true,
    },
  },

  setup() {

  },
}
</script>
