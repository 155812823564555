var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.agencyData === undefined
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.errFetchAgencyData')) + " ")])]), _vm.agencyData ? _c('b-card', {
    staticClass: "border",
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h6', {
          staticClass: "m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('previewMail')) + " ")])];
      },
      proxy: true
    }], null, false, 639504834)
  }, [_c('b-card-body', [_c('h3', [_vm._v(" " + _vm._s(_vm.$t('createdMsg_1')))]), _c('b-card-text', {
    staticClass: "py-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('sendTo')) + " "), _c('span', {
    staticClass: "font-weight-bold "
  }, [_vm._v(_vm._s(_vm.agencyData.emailAddress))])]), _c('b-card-text', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('createdMsg_2')) + " ")]), _c('b-list-group', {
    staticClass: "mb-2"
  }, [_c('b-list-group-item', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('email')) + " ")]), _c('b-col', [_vm._v(" " + _vm._s(_vm.agencyData.emailAddress) + " ")])], 1)], 1), _c('b-list-group-item', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('password')) + " ")]), _c('b-col', [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.$t('passwordMuted')))])])], 1)], 1)], 1), _c('b-card-text', {
    staticClass: "font-weight-bold mt-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('createdMsg_3')) + " ")])], 1), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-button', {
    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
    attrs: {
      "variant": "secondary",
      "type": "reset",
      "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('prev-step');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), _c('b-button', {
    staticClass: "mb-1 mb-sm-0 ml-0 ml-sm-1",
    attrs: {
      "variant": "info",
      "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('submit');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('send')) + " ")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }