<template>
  <IAmOverlay :loading="loading">
    <b-card>
      <form-wizard
        ref="refFormWizard"
        :title="null"
        :subtitle="null"
        hide-buttons
        class="checkout-form-wizard steps-transparent"
      >
        <tab-content
          :title="$t('account')"
          icon="feather icon-user"
        >
          <agencies-add-tab-account
            :agency-data.sync="agencyData"
            @next-step="formWizardNextStep"
          />
        </tab-content>
        <tab-content
          :title="$t('confirmation')"
          icon="feather icon-check"
        >
          <agencies-add-tab-confirm
            :agency-data.sync="agencyData"
            @prev-step="formWizardPrevStep"
            @submit="submitHandle"
          />
        </tab-content>
      </form-wizard>
    </b-card>
  </IAmOverlay>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import { FormWizard, TabContent } from 'vue-form-wizard'

import IAmOverlay from '@/components/IAmOverlay.vue'
import store from '@/store'
import { useRouter } from '@/@core/utils/utils'

import AgenciesAddTabAccount from './AgenciesAddTabAccount.vue'
import AgenciesAddTabConfirm from './AgenciesAddTabConfirm.vue'
import agenciesStoreModule from '../agenciesStoreModule'
import useAgenciessHandle from '../useAgenciesHandle'

export default {
  components: {
    BCard,
    FormWizard,
    TabContent,
    IAmOverlay,

    // components
    AgenciesAddTabAccount,
    AgenciesAddTabConfirm,
  },
  setup() {
    const AGENCIES_APP_STORE_MODULE_NAME = 'app-agencies'

    // Register module
    if (!store.hasModule(AGENCIES_APP_STORE_MODULE_NAME)) store.registerModule(AGENCIES_APP_STORE_MODULE_NAME, agenciesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AGENCIES_APP_STORE_MODULE_NAME)) store.unregisterModule(AGENCIES_APP_STORE_MODULE_NAME)
    })

    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }
    const formWizardPrevStep = () => {
      refFormWizard.value.prevTab()
    }

    const agencyData = ref({
      creator: null, // required
      agencyName: null, // required
      agencyOwner: {
        name: null, // required
        phoneNumber: null,
      },
      status: null, // required, enums: [NEW, PENDING_CONTRACT, CONTRACTED, DELIVERED, CONTRACTING]
      enableSendMonthlyReport: null, // required, boolean
      // taxCode: null,
      // avatar: null,
      // emailAddress: null,
      // isActive: null, // boolean
      // bankCode: null,
      // bankName: null,
      // businessFieldAmount: null, // number
      // holderName: null,
      // identityCardNumber: null,
      // identityCardName: null,
      // placeOfIssue: null,
      airAccount: {},
      documentContractUrl: [],
    })

    const { router } = useRouter()
    const { addAgencies } = useAgenciessHandle()
    const loading = ref(false)
    const submitHandle = () => {
      loading.value = true
      addAgencies(agencyData.value)
        .then(() => {
          router.push({ name: 'apps-agencies-list' })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      // form
      refFormWizard,
      formWizardNextStep,
      formWizardPrevStep,

      agencyData,
      submitHandle,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
